import React from "react";
import Carousel from "react-multi-carousel";
import "../css/Actividades.css";
import actividad1 from "../images/actividad1.jpg";
import actividad2 from "../images/actividad2.jpg";
import actividad3 from "../images/actividad3.jpg";
import actividad4 from "../images/actividad4.jpg";
import actividad5 from "../images/actividad5.jpg";
import actividad6 from "../images/actividad6.jpg";
import actividad7 from "../images/actividad7.jpg";
import actividad8 from "../images/actividad8.jpg";

function Actividades() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="Actividades">
      <h1>CONOCE ALGUNA DE NUESTRAS ACTIVIDADES</h1>
      <Carousel
        containerClass="carousel-actividad"
        showArrows={true} // Mostrar las flechas para deslizar
        infiniteLoop={false} // No hacer un loop infinito
        showStatus={false} // Ocultar el contador de páginas
        showThumbs={false} // Ocultar los thumbnails
        draggable={false} // No permitir que el usuario pueda arrastrar los elementos
        responsive={responsive}>
        <div className="actividad">
          <img src={actividad1}></img>
        </div>
        <div className="actividad">
          <img src={actividad2}></img>
        </div>
        <div className="actividad">
          <img src={actividad3}></img>
        </div>
        <div className="actividad">
          <img src={actividad4}></img>
        </div>
        <div className="actividad">
          <img src={actividad5}></img>
        </div>
        <div className="actividad">
          <img src={actividad6}></img>
        </div>
        <div className="actividad">
          <img src={actividad7}></img>
        </div>
        <div className="actividad">
          <img src={actividad8}></img>
        </div>
      </Carousel>
    </div>
  );
}

export default Actividades;
