import React from "react";
import logo from "../images/logo_agrouls.jpg";
import "../css/Navbar.css";

const Navbar = () => {
  return (
    <nav className="navbar">
      <ul className="navbar-logo-menu">
        <img src={logo} alt="logo"></img>
        <div className="navbar-menu">
          <li className="navbar-item">INICIO</li>
          <li className="navbar-item">
            INGENIERÍA AGRONÓMICA
            <ul className="dropdown-menu">
              <li>
                <a href="#">ESCUELA</a>
              </li>
              <li>
                <a href="#">PERFIL PROFESIONAL</a>
              </li>
              <li>
                <a href="#">CUERPO ACADÉMICO</a>
              </li>
              <li>
                <a href="#">SEMINARIO DE TÍTULO</a>
              </li>
              <li>
                <a href="#">CEC</a>
              </li>
              <li>
                <a href="#">EMPLEO Y PRÁCTICAS</a>
              </li>
              <li>
                <a href="#">DOCUMENTOS</a>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            DEPARTAMENTO DE AGRONOMÍA
            <ul className="dropdown-menu">
              <li>
                <a href="#">NOSOTROS</a>
              </li>
              <li>
                <a href="#">ACADÉMICOS</a>
              </li>
              <li>
                <a href="#">SERVICIOS</a>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            REVISTA
            <ul className="dropdown-menu">
              <li>
                <a href="#">INSTRUCCIONES A LOS AUTORES</a>
              </li>
              <li>
                <a href="#">DESCARGAS</a>
              </li>
            </ul>
          </li>
          <li className="navbar-item">CONTACTO</li>
        </div>
      </ul>
    </nav>
  );
};

export default Navbar;
