import React from "react";
import "../css/Slider.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import slider1 from "../images/slider1.jpg";
import slider2 from "../images/slider2.jpg";
import slider3 from "../images/slider3.jpg";
import slider4 from "../images/slider4.jpg";
import slider5 from "../images/slider5.jpg";
import slider6 from "../images/slider6.jpg";
import slider7 from "../images/slider7.jpg";

const Slider = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="Slider">
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        ssr={true}
        infinite={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        responsive={responsive}>
        <div className="slide-item">
          <h1>INGENIERÍA AGRONÓMICA</h1>
          <img src={slider1} alt="Slide 1" />
        </div>
        <div className="slide-item">
          <h1>INGENIERÍA AGRONÓMICA</h1>
          <img src={slider2} alt="Slide 2" />
        </div>
        <div className="slide-item">
          <h1>INGENIERÍA AGRONÓMICA</h1>
          <img src={slider3} alt="Slide 3" />
        </div>
        <div className="slide-item">
          <h1>INGENIERÍA AGRONÓMICA</h1>
          <img src={slider4} alt="Slide 4" />
        </div>
        <div className="slide-item">
          <h1>INGENIERÍA AGRONÓMICA</h1>
          <img src={slider5} alt="Slide 5" />
        </div>
        <div className="slide-item">
          <h1>INGENIERÍA AGRONÓMICA</h1>
          <img src={slider6} alt="Slide 6" />
        </div>
        <div className="slide-item">
          <h1>INGENIERÍA AGRONÓMICA</h1>
          <img src={slider7} alt="Slide 7" />
        </div>
      </Carousel>
    </div>
  );
};

export default Slider;
