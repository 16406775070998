import React from "react";
import "../css/Informacion.css";

const Informacion = () => {
  return (
    <div className="Informacion">
      <div className="contenedorInfo" style={{ backgroundColor: "#DF504B" }}>
        <h1>Biblioteca</h1>
        <p>
          Nuestra biblioteca cuenta con una amplio stock de títulos para
          nuestros estudiantes y laboratorios de computación.
        </p>
      </div>
      <div className="contenedorInfo" style={{ backgroundColor: "#1A61A6" }}>
        <h1>Educación Continua</h1>
        <p>
          Los egresados de nuestra carrera pueden optar a los programas de
          postítulo y postgrado que la Universidad tiene vigentes.
        </p>
      </div>
      <div className="contenedorInfo" style={{ backgroundColor: "#384069" }}>
        <h1>Docentes</h1>
        <p>
          El cuerpo docente de la carrera Ing. Agronómica, posee una vasta
          experiencia en formación académica y profesional.
        </p>
      </div>
      <div className="contenedorInfo" style={{ backgroundColor: "#44AFAE" }}>
        <h1>Exámenes de Titulación</h1>
        <p>Conozca el calendario de Exámenes de Titulación.</p>
      </div>
    </div>
  );
};

export default Informacion;
