import React from "react";
import "../css/Datos.css";
import imagenDatos from "../images/Frontis-de-la-ULS-Limari.jpg";
import icono1 from "../images/icon_empleabilidad.png";
import icono2 from "../images/icon_vacantes.png";
import icono3 from "../images/icon_academico.png";
import icono4 from "../images/icon_experiencia.png";

function Datos() {
  return (
    <div className="Datos">
      <h1 className="tituloDatos">DATOS INTERESANTES</h1>
      <div className="contenedorImagenDatos">
        <div className="contenedorImagen">
          <img src={imagenDatos}></img>
        </div>
        <div className="contenedorDatos">
          <h1>ALGUNOS DATOS</h1>
          <div className="contenedorDatosIndividuales">
            <div className="dato">
              <div className="logoDato">
                <img src={icono1}></img>
              </div>
              <div className="contenedorTextoDato">
                <h2>79 %</h2>
                <p>Empleabilidad al 2° año de egreso</p>
              </div>
            </div>
            <div className="dato">
              <div className="logoDato">
                <img src={icono2}></img>
              </div>
              <div className="contenedorTextoDato">
                <h2>45</h2>
                <p>Vacantes</p>
              </div>
            </div>
            <div className="dato">
              <div className="logoDato">
                <img src={icono3}></img>
              </div>
              <div className="contenedorTextoDato">
                <h2>49</h2>
                <p>Cuerpo Académico</p>
              </div>
            </div>
            <div className="dato">
              <div className="logoDato">
                <img src={icono4}></img>
              </div>
              <div className="contenedorTextoDato">
                <h2>25</h2>
                <p>Años de experiencia</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Datos;
