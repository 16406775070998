import React from "react";
import "../css/Footer.css";
import logos from "../images/Recurso-3@5x.png";

function Footer() {
  return (
    <div className="Footer">
      <div className="footerSuperior">
        <img src={logos}></img>
      </div>
      <div className="footerInferiror">
        <p>Campus Andres Bello – Cisternas #1200 – La Serena</p>
        <p>Campus Limarí – Av. La Paz #1108 – Ovalle</p>
      </div>
    </div>
  );
}

export default Footer;
